import React, {useState, useEffect, useRef} from "react";
import useInput from '../../hooks/useInput.js';
import PubNub from 'pubnub';
import {Button, Input} from '@material-ui/core';
import "./Chat.scss";
import {setCookie} from "../../store/actions/actions";

const publishKey = "pub-c-6b5d514f-3443-4ebf-b07d-a9e207f0b974";
const subscribeKey = "sub-c-39ef0ede-566f-11eb-bf6e-f20b4949e6d2";

function Chat(props) {

    let defaultChannel = "Global";
    if (props.channel) defaultChannel = props.channel;
    const [channel, setChannel] = useState(defaultChannel);
    const [messages, setMessages] = useState([]);
    const [username, setUsername] = useState(props.username);
    const tempName = useInput();
    const tempMessage = useInput();
    const messagesEndRef = useRef(null);

    if (channel !== props.channel) {
        setChannel(props.channel);
    }

    //This is where we set up PubNub and handle events that come through. Reruns on channel name update!
    useEffect(() => {
        const pubnub = new PubNub({
            publishKey: publishKey,
            subscribeKey: subscribeKey,
            uuid: username
        });

        console.log("listen to channel:", channel);
        pubnub.addListener({
            status: function (statusEvent) {
                if (statusEvent.category === "PNConnectedCategory") {
                    console.log("Connected to PubNub!")
                }
            },
            message: function (msg) {
                if (msg.message.text) {
                    let newMessages = [];
                    newMessages.push({
                        uuid: msg.message.uuid,
                        text: msg.message.text
                    });
                    setMessages(messages => messages.concat(newMessages))
                    scrollToBottom();
                }
            }
        });
        //Subscribes to the channel in our state
        pubnub.subscribe({
            channels: [channel]
        });
        pubnub.history(
            {
                channel: channel,
                count: 10, // 100 is the default
                stringifiedTimeToken: true // false is the default
            }, function (status, response) {
                let newMessages = [];
                for (let i = 0; i < response.messages.length; i++) {
                    newMessages.push({
                        uuid: response.messages[i].entry.uuid,
                        text: response.messages[i].entry.text
                    });
                }
                setMessages(messages => messages.concat(newMessages));
            }
        );
        return function cleanup() {
            console.log("shutting down pubnub");
            pubnub.unsubscribeAll();
            setMessages([]);
        }
    }, [channel, username]);
    //Adding back browser button listener
    useEffect(() => {
        window.addEventListener("popstate", goBack);
        return function cleanup() {
            window.removeEventListener("popstate", goBack);
        }
    }, []);

    useEffect(scrollToBottom, [messages]);

    function handleKeyDown(event) {
        if (event.target.id === "messageInput") {
            if (event.key === 'Enter') {
                publishMessage();
            }
        }
        if (event.target.id === "nameInput") {
            if (event.key === 'Enter') {
                setName();
            }
        }
    }

    function setName() {
        if (tempName.value) {
            setUsername(tempName.value);
            setCookie("user", {name: tempName.value}, 3600)
        }
    }

    //Publishing messages via PubNub
    function publishMessage() {
        if (tempMessage.value) {
            let messageObject = {
                text: tempMessage.value,
                uuid: username
            };
            const pubnub = new PubNub({
                publishKey: publishKey,
                subscribeKey: subscribeKey,
                uuid: username
            });
            pubnub.publish({
                message: messageObject,
                channel: channel
            });
            tempMessage.setValue('');
        }
    }

    function goBack() {
        //Access the parameters provided in the URL
        let query = window.location.search.substring(1);
        if (!query) {
            setChannel("Global")
        } else {
            let params = query.split("&");
            for (let i = 0; i < params.length; i++) {
                var pair = params[i].split("=");
                //If the user input a channel then the default channel is now set
                //If not, we still navigate to the default channel.
                if (pair[0] === "channel" && pair[1] !== "") {
                    setChannel(decodeURI(pair[1]))
                }
            }
        }
    }

    function scrollToBottom() {
        messagesEndRef.current.scrollIntoView({behavior: 'smooth'})
    }

    //This returns how our page will look, including a couple
    // components into the heirarchy to help organize our page. We can also pass information down
    //to our children through props.
    return (
        <div className={'chat-container'}>
            <div className={'chat'}>
                <div className={'chat-log'}>
                    <div className="top"/>
                    <div>
                        <Log messages={messages}/>
                    </div>
                    <div ref={messagesEndRef}/>
                </div>
                {
                    username ? <div className={'chat-input'}>
                        <Input
                            placeholder="Enter a message"
                            fullWidth={true}
                            id="messageInput"
                            value={tempMessage.value}
                            onChange={tempMessage.onChange}
                            onKeyDown={handleKeyDown}
                            inputProps={{'aria-label': 'Message Field',}}
                            autoFocus={true}
                        />
                        <Button
                            size="small"
                            color="primary"
                            onClick={publishMessage}>
                            Send
                        </Button>
                    </div> : <div className={'chat-name'}>
                        <Input
                            placeholder="Choose a name"
                            fullWidth={true}
                            id="nameInput"
                            value={tempName.value}
                            onChange={tempName.onChange}
                            onKeyDown={handleKeyDown}
                            inputProps={{'aria-label': 'Message Field',}}
                            autoFocus={true}
                        />
                        <Button
                            size="small"
                            color="primary"
                            onClick={setName}>
                            Send
                        </Button>
                    </div>
                }
            </div>
        </div>
    );
}

//Log functional component that is the list of messages
function Log(props) {
    return (
        <div>
            {
                props.messages.map((item, index) => (
                    <div key={index} className={'chat-card text-left'}>
                        <div className={'row'}>
                            <div className={'col-md-12'}>
                                <div className={'chat-message'}>
                                    <h5>{item.uuid}</h5>
                                    <p>{item.text}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                ))
            }
        </div>
    )
}

export default Chat;