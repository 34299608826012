import React, {Component} from "react";
import {connect} from "react-redux";
import "./SingIn.scss";
import * as actions from "../../store/actions/actions";
import axios from "axios";
import mainVideo from "../../assets/video/livem.mp4";

class SignIn extends Component {
    constructor(props) {
        super(props);
        this.state = {
            controls: {
                code: {
                    elementType: "input",
                    elementConfig: {
                        type: "text",
                        placeholder: "נא להקליד קוד",
                    },
                    value: "",
                    validation: {
                        required: true,
                        isLetterNumeric: true,
                    },
                    valid: true,
                    touched: true,
                },
            },
            errorMess: null,
        }
    }

    checkValidity = (value, rules) => {
        let isValid = true;
        if (!rules) {
            return true;
        }
        if (rules.required) {
            isValid = value.trim() !== "" && isValid;
        }

        if (rules.isLetterNumeric) {
            const pattern = /^[0-9a-zA-Z]+$/;
            isValid = pattern.test(value) && isValid;
        }
        return isValid;
    };
    // think about delete this
    inputChangedHandler = (event) => {
        const updatedControls = {
            ...this.state.controls,
            code: {
                ...this.state.controls.code,
                value: event.target.value.toUpperCase(),
                valid: this.checkValidity(
                    event.target.value,
                    this.state.controls.code.validation
                ),
                touched: true,
            },
        };
        this.setState({...this.state, controls: updatedControls});
    };

    submitHandler = async (event) => {
        event.preventDefault();
        if (!this.state.controls.code.valid) {
            this.setState({
                errorMess: "אנא בדוק את הקוד שלך בבקשה",
            });
        } else {
            this.setState({errorMess: null});

            let config = {
                method: 'get',
                url: `https://livem.tv/api/?code=${this.state.controls.code.value}`,
                headers: {
                    'Action': 'getClient'
                }
            };

            let self = this;
            axios(config).then(function (response) {
                if (response.data.status) {
                    self.props.setClient(response.data.client)
                    actions.setCookie('client', JSON.stringify(response.data.client), 86400)
                    self.props.history.push('/live');
                } else {
                    actions.removeCookie('client');
                    self.setState({
                        errorMess: "הקוד שהוקש אינו קיים במערכת",
                    });
                }
            }).catch(function (error) {
                self.setState({
                    errorMess: "ארעה תקלה, נא לנסות שוב",
                });
            });
        }
    };

    componentDidMount() {
        //load general settings
        this.props.getSettings();
        if (this.props.match.params.client) {
            let controls = this.state.controls;
            controls.code.value = this.props.match.params.client;
            this.setState({...this.state, controls: controls});

            if (this.props.match.params.client) {
                axios({
                    method: 'get',
                    url: `https://livem.tv/api/?code=${this.props.match.params.client}`,
                    headers: {
                        'Action': 'getClient'
                    }
                }).then((response) => {
                    if (response.data.status) {
                        this.props.setClient(response.data.client)
                        actions.setCookie('client', JSON.stringify(response.data.client), 86400)
                        this.props.history.push('/live');
                    } else {
                        controls.code.value = "";
                        this.setState({...this.state, controls: controls});
                    }
                });
            }
        }
    }

    render() {
        const formElementsArray = [];
        for (let key in this.state.controls) {
            formElementsArray.push({
                id: key,
                config: this.state.controls[key],
            });
        }

        let form = formElementsArray.map((formElement) => (
            <input
                className="form-control text-center text-white bg-transparent border-top-0 border-left-0 border-right-0"
                type="text"
                placeholder="הקלידו קוד כניסה"
                key={formElement.id}
                elementtype={formElement.config.elementType}
                elementconfig={formElement.config.elementConfig}
                value={formElement.config.value}
                invalid={"" + !formElement.config.valid}
                shouldvalidate={formElement.config.validation}
                touched={"" + formElement.config.touched}
                onChange={this.inputChangedHandler}
            />
        ));
        let errorMessage = null;
        if (this.state.errorMess) {
            errorMessage = (
                <h6 className="mt-2 text-danger" dir="rtl">
                    <small>{this.state.errorMess}</small>
                </h6>
            );
        }

        return (
            <div className={'sign-page'}>
                {this.props.settings.show_video_main === '1' ?
                    <video className={'bg-video'} src={mainVideo} autoPlay preload="true" muted playsInline/>
                    : ''}
                <div className="sign-container bg-trans-grey rounded p-4">
                    <form onSubmit={this.submitHandler} className="">
                        {form}
                        {errorMessage}
                        <button type="submit" className="btn btn-primary mt-4 btn-block">
                            המשך
                        </button>
                        <p>הקוד שקיבלתם הינו <span className={'pink'}>אישי ומותאם למשתמש אחד בלבד</span>. לאחר הזנת
                            הקוד תצרו לעצמכם משתמש אישי לפעילות.</p>
                    </form>
                </div>
            </div>
        );
    }
}

//here we can add auto auth
const mapStateToProps = (state) => {
    return state.auth;
};

const mapDispatchToProps = (dispatch, state) => {
    return {
        setClient: (client) => dispatch(actions.setClient(client)),
        getSettings: () => dispatch(actions.getSettings()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SignIn);
