import React, {Component} from "react";
import axios from "axios";
import {connect} from "react-redux";
import Chat from "../../components/UI/Chat";

const timers = {show: null}

export class Interactive extends Component {

    constructor(props) {
        super(props);
        this.state = {
            links: [],
            channel: '',
            username: this.props.user.name,
            reload: false,
            showChat: false,
            showLinks: false,
        };
    }

    getLinks(id) {
        axios({
            method: 'get',
            url: `https://livem.tv/api/?id=${id}`,
            headers: {
                'Action': 'getShow'
            }
        })
            .then((response) => {
                if (response.data.status) {
                    this.setState({
                        links: response.data.show.links,
                        channel: response.data.show.chat_channel,
                        reload: response.data.show.reload === 1,
                        showLinks: response.data.show.show_links === 1,
                        showChat: response.data.show.chat_enabled === 1
                    });
                    //request again show details
                    clearTimeout(timers.show);
                    if (this.state.reload)
                        timers.show = setTimeout(() => {
                            this.getLinks(this.props.selectedShow);
                        }, 15000);
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    componentDidUpdate(prevProps) {
        let show = this.props.show;
        if (show.show_links !== prevProps.show.show_links || show.chat_enabled !== prevProps.show.chat_enabled || show.reload !== prevProps.show.reload || show.links.length !== prevProps.show.links.length) {

            this.setState({
                ...this.state,
                links: show.links,
                channel: show.chat_channel,
                reload: show.reload === 1,
                showLinks: show.show_links === 1,
                showChat: show.chat_enabled === 1
            })

            if (show.id && show.reload === 1) {
                clearTimeout(timers.show);
                timers.show = setTimeout(() => {
                    this.getLinks(this.props.selectedShow);
                }, 15000);
            }
        }
    }

    handleLinkClick = (url) => {
        if (url.includes('quizy') || url.includes('vote'))
            window.open(url, 'Quizy', 'height=648,width=360');
        else if (url.includes('livem'))
            window.open(url, '_self');
        else
            window.open(url, '_blank');
    }

    render() {
        return (
            <div className={'interactive'}>
                {
                    this.state.showChat && this.state.channel &&
                    <Chat channel={this.state.channel} username={this.state.username}/>
                }
                <div className={"links-container font-small flex-full " + (this.state.showLinks ? 'show' : '')}>
                    {
                        this.state.links.map((link, k) => {
                            return (
                                <div className='links-item ' key={'link-' + k}>
                                    <span className='link' style={{
                                        color: link.link_text_color,
                                        backgroundColor: link.link_background_color,
                                        width: "line-height"
                                    }} onClick={() => this.handleLinkClick(link.link_url)}>{link.link_text}</span>
                                </div>
                            )
                        })
                    }
                </div>
            </div>

        );
    }
}

const mapStateToProps = (state) => {
    return state.auth;
};

export default connect(mapStateToProps)(Interactive);