import React from "react";

const WarningText = () => {
  return (
    <div>
      <h6 className="textBoxOne my-2 mb-1 rtl">
        <small className="text-muted ">
          הקוד שקיבלתם הינו{" "}
          <span className="text-danger"> אישי ומותאם למשתמש אחד בלבד</span>
          <span className="text-break">
            {" "}
            לאחר הזנת הקוד תצרו לעצמכם משתמש אישי לפעילות
          </span>
        </small>
      </h6>
    </div>
  );
};

export default WarningText;
