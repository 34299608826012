import React, { Component } from "react";
import WarningText from "../../components/UI/WarningText";

import PinInput from "react-pin-input";
import {connect} from "react-redux";
import Modal from "react-bootstrap/Modal";
import {MDBContainer} from "mdbreact";
import InputDate from "../../components/UI/PopWindow/InputDate";
import CheckMe from "../../components/UI/PopWindow/CheckMe";
import "../../components/UI/PopWindow/PopWindow.css";
import "../../components/UI/PopWindow/scrollbar.css";
import * as actions from "../../store/actions/actions";

class Auth extends Component {
  state = {
    controls: {
      phone: {
        elementType: "input",
        elementConfig: {
          type: "tel",
          placeholder: "מספר נייד",
        },
        value: "",
        validation: {
          required: true,
          isNumeric: true,
          minLength: 10,
          maxLength: 13,
        },
        valid: false,
        touched: false,
      },
    },
    time: 10,

    errorMess: null,
    sendCode: false,
    wait: false,
    sendAgain: false,
    showModel: false,
    submitTerms: false,
  };

  checkValidity(value, rules) {
    let isValid = true;
    if (!rules) {
      return true;
    }

    if (rules.required) {
      isValid = value.trim() !== "" && isValid;
    }
    if (rules.minLength) {
      isValid = value.length >= rules.minLength && isValid;
    }

    if (rules.maxLength) {
      isValid = value.length <= rules.maxLength && isValid;
    }
    if (rules.isNumeric) {
      // const pattern = /^\d+$/;
      const pattern = /^\d{10}$/;

      isValid = pattern.test(value) && isValid;
    }

    return isValid;
  }

  inputChangedHandler = (event, controlName) => {
    const updatedControls = {
      ...this.state.controls,
      [controlName]: {
        ...this.state.controls[controlName],
        value: event.target.value,
        valid: this.checkValidity(
            event.target.value,
            this.state.controls[controlName].validation
        ),
        touched: true,
      },
    };
    this.setState({ controls: updatedControls });
    if (this.state.sendCode) {
      this.setState({ sendCode: !this.state.sendCode });
      this.setState({ wait: false });
      this.setState({ sendAgain: false });
    }
  };

  submitPhoneNumber = (event) => {
    event.preventDefault();
    console.log(this.state);

    if (
        !this.state.controls.phone.valid &&
        this.state.controls.phone.value !== ""
    ) {
      this.setState({
        errorMess: "קלט לא חוקי",
      });
    } else {
      this.setState({ errorMess: null });
    }
    console.log(
        "error: " + this.state.errorMess,
        "valid: " + this.state.controls.phone.valid
    );

    if (this.state.errorMess === null && this.state.controls.phone.valid) {
      if (!this.state.sendAgain) {
        this.setState({sendCode: !this.state.sendCode});
      }
      if (this.state.sendAgain) {
        this.setState({sendAgain: !this.state.sendAgain});
      }
      this.setState({wait: !this.state.wait});

      console.log("Now Im here!!!");

      this.timer();
    }
  };

  checkCode = (pinCode) => {
    const sendPin = "12345";
    if (sendPin === pinCode) {
      this.setState({ showModel: !this.state.showModel });
    }
  };

  timer = () => {
    this.myInterval = setInterval(() => {
      if (this.state.time > 0) {
        this.setState({
          time: this.state.time - 1,
        });
        // console.log("time: ", this.state.time);
      }
      if (this.state.time === 0) {
        // console.log("wait: ", this.state.wait);
        if (this.state.wait) {
          this.setState({ wait: false });
          this.setState({ sendAgain: true });
        }
        clearInterval(this.myInterval);
        this.setState({ time: 10 });
      }
    }, 1000);
  };

  submitModelHandler = () => {
    actions.changeToolbarTitle();
    this.setState({submitTerms: !this.state.submitTerms});

    // actions.changeToolbarTitle();

    this.props.history.push("/live");
  };

  //when user confirm phone number to send code => need to count with timer 10s and after time out change the btn to "disabled"
  render() {

    const formElementsArray = [];
    for (let key in this.state.controls) {
      formElementsArray.push({
        id: key,
        config: this.state.controls[key],
      });
    }

    let form = formElementsArray.map((formElement) => (
        <input
            className="form-control text-right text-white bg-transparent border-top-0 border-left-0 border-right-0"
            type="tel"
            placeholder={formElement.config.elementConfig.placeholder}
            key={formElement.id}
            elementtype={formElement.config.elementType}
            elementconfig={formElement.config.elementConfig}
            value={formElement.config.value}
            invalid={"" + !formElement.config.valid}
            shouldvalidate={formElement.config.validation}
            // pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
            touched={"" + formElement.config.touched}
            onChange={(event) => this.inputChangedHandler(event, formElement.id)}
        />
    ));

    let errorMessage = null;
    if (this.state.errorMess !== null) {
      errorMessage = (
          <h6 className="mt-2 text-danger" dir="rtl">
            <small>{this.state.errorMess}</small>
          </h6>
      );
    }
    let sendCode = this.state.sendCode;
    let wait = this.state.wait;
    let sendAgain = this.state.sendAgain;
    //col-sm-3 bg-dark rounded justify-content-center align-self-center

    return (
        <div className="row justify-content-center">
          <div className="col-sm-8 bg-dark rounded">
         {this.state.showModel ? (<div><Modal
              size="lg"
              show={this.state.showModel}
              onHide={() => this.setState({show: !this.state.showModel})}
              aria-labelledby="example-modal-sizes-title-lg"
              className="text-center text-light  bg-dark customModal"
          >
            <Modal.Header closeButton className="customModal">
              <Modal.Title id="example-modal-sizes-title-lg ">
                <div className="">
                  <h3 className="modal-title w-100 position-absolute">
                    !ברוכים הבאים
                  </h3>
                </div>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="customModal">
              <div className="row">
                <div className="offset-md-2 col-md-8">
                  <h4 className="mb-4 text-center">{this.props.customTitle}</h4>
                  <MDBContainer className="customModal mb-4">
                    <div className="scrollbar scrollbar-near-moon">
                      <div
                          className=" text-right force-overflow mr-2"
                          style={{ maxHeight: "200px" }}
                      >
                        {this.props.customTerms}
                      </div>
                    </div>
                  </MDBContainer>
                  <div className="mb-2 ">
                    <InputDate />
                  </div>

                  <CheckMe />

                  <button
                      type="submit"
                      className="btn btn-primary my-4"
                      onClick={this.submitModelHandler}
                  >
                    להרשמה
                  </button>
                </div>
              </div>
            </Modal.Body>
          </Modal></div>) : null}

          <div className="my-3 bg-dark">
              <form
                className="form-control-tel d-flex flex-row-reverse my-3 mt-2 justify-content-center">
              <div className="ml-2">{form}</div>

              {!sendCode && (
                  <button
                      type="submit"
                      value="Submit"
                      className="btn btn-primary "
                      onClick={this.submitPhoneNumber}
                  >
                    התחברו
                  </button>
              )}

              {wait && (
                  <button
                      type="submit"
                      value="Submit"
                      className="btn btn-primary"
                      disabled
                  >
                    הקלידו קוד
                  </button>
              )}

              {sendAgain && (
                  <button
                      type="submit"
                      value="Submit"
                      className="btn btn-primary "
                      onClick={this.submitPhoneNumber}
                  >
                    שלח שוב
                  </button>
              )}
          </form>

          {"   "}
          {errorMessage}

          </div>
          <div className="my-3 bg-dark">
            {sendCode && (
                <form onSubmit={this.checkCode}>
                  <div className="my-1 bg-dark">
                    <PinInput
                        length={5}
                        initialValue=""
                        secret
                        onChange={(value, index) => {}}
                        type="numeric"
                        inputMode="number"
                        style={{ padding: "10px" }}
                        inputStyle={{ borderColor: "white", color: "white" }}
                        inputFocusStyle={{ borderColor: "gray" }}
                        onComplete={(value, index) => {
                          this.checkCode(value);
                        }}
                    />
                  </div>
                </form>
            )}
          </div>

          <div className="my-3 bg-dark">
            <h6 className="my-4 ">
              <small className="text-muted">
                עדיין לא בוצע אימות למס זה, לשליחת מסרון נוסף{" "}
                <a href="/" className="">
                  ליחצו כאן
                </a>
              </small>
            </h6>
            <WarningText />
            <h5 className="my-4">
              <small>
                ליצירת משתמש חדש באמצעות קוד{" "}
                <a href="/" className="text-decoration-none">
                  ליחצו כאן
                </a>
              </small>
            </h5>
          </div>
          </div>
        </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    customTitle: state.auth.title,
    customTerms: state.auth.terms,

  };
};

export default connect(mapStateToProps)(Auth);
