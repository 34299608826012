import React from "react";
import * as actions from '../../../store/actions/actions';
import {MDBFooter} from "mdbreact";
import "./footer.css";

const Footer = (props) => {

    const reset = () => {
        actions.removeCookie('show');
        actions.removeCookie('client');
        document.location.href = "/" + (props.client.code ? ("c/" + props.client.code) : '');
    }
    return (
        <MDBFooter
            color="blue"
            className="font-small bg-dark">
            <div className="footer-copyright py-2 text-light">
                <button className={'link-button'} onClick={reset}>איפוס נתונים</button>
                &nbsp;&nbsp;
                &copy; {new Date().getFullYear()} Copyright {" "}
                {
                    props.client.name ? (<a href={props.client.website} target={'_blank'}
                                            rel="noopener noreferrer">{props.client.name}</a>)
                        : (<a href="https://www.livem.info/" target={'_blank'}
                              rel="noopener noreferrer"> Livem.tv</a>)
                }
            </div>
        </MDBFooter>
    );
};

export default Footer;
