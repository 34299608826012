import * as actionTypes from "../actions/actionTypes";

const reducer = (state = {
    auth: {
        isSessionAuthorized: false,
        isClientAuthorized: false,
        toolbarTitle: null,
        error: null,
        loading: false,
        token: null,
    },
    settings: {
        no_code_url: "",
    },
    client: {
        code: null,
        background: null,
        create_date: null,
        id: null,
        logo: "https://livem.tv/uploads/playzone/Livem.png",
        name: null,
        terms: null,
        title: null,
        terms_url: null,
        shows: []
    },
    show: {
        id: null,
        video_type: '',
        video_channel: '',
        chat_enabled: '',
        links: [],
        reload: 0,
    },
    selectedShow: -1,
    showView: false,
    user: {
        name: '',
        tel: null,
    },
}, action) => {
    switch (action.type) {
        case actionTypes.AUTH_START:
            return {
                ...state,
                error: null,
                loading: true
            };
        case actionTypes.AUTH_SUCCESS_CLIENT:
            return {
                ...state,
                client: action.client,
            };
        case actionTypes.AUTH_FAIL:
            return {
                ...state,
                error: action.error,
                loading: false,
            };
        case actionTypes.AUTH_LOGOUT:
            return {
                ...state,
                code: null,
                userId: null
            };
        case actionTypes.SET_SETTINGS:
            return {
                ...state,
                settings: action.settings
            };
        case actionTypes.SET_CLIENT:
            return {
                ...state,
                client: action.client
            };
        case actionTypes.SET_SHOW:
            return {
                ...state,
                show: action.show,
                selectedShow: action.show.id
            };
        case actionTypes.SET_SHOW_SELECTED:
            return {
                ...state,
                selectedShow: action.selectedShow
            };
        case actionTypes.CHANGE_TITLE:
            return {
                ...state,
                toolbarTitle: state.title,
            };
        case actionTypes.SET_USER:
            return {
                ...state,
                user: action.user,
            };
        case actionTypes.SET_SHOW_VIEW:
            return {
                ...state,
                showView: action.showView,
            };
        default:
            return state;
    }
};

export default reducer;