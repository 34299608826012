import React, {Component} from "react";
import {connect} from "react-redux";
import "./Navbar.scss";
import Burger from "../../../assets/Images/burger.png";
import * as actions from "../../../store/actions/actions";

export class Navbar extends Component {

    handleShowSelect = (event) => {
        this.props.setSelectedShow(event.target.value);
        this.props.getShow(event.target.value);
    }

    render() {
        return <nav className="navbar bg-dark d-flex flex-apart">
            <div>
                {this.props.customerLogo ? (
                    <img
                        src={this.props.customerLogo}
                        width="auto"
                        height="40"
                        className="d-inline-block align-top"
                        alt="Custom Icon"
                    />
                ) : (<h1 className="navbar-brand">{this.props.customerName}</h1>)}
            </div>
            <div className={'d-flex flex-apart'}>
                {
                    this.props.customerShows && this.props.customerShows.length ? (
                        <select className={'navbar-show bg-dark'} value={this.props.selectedShow} onChange={(event) => {
                            this.handleShowSelect(event)
                        }}>
                            {
                                this.props.customerShows.map((show, x) => {
                                    return (<option key={'show-' + x} value={show.id}>{show.name}</option>)
                                })
                            }
                        </select>) : ''
                }
                <div className="navbar-burger"
                     onClick={this.props.drawerToggleClicked}>
                    <img
                        src={Burger}
                        alt="MyIcon"
                        // className="rounded-circle"
                        width="35"
                        height="35"
                    />
                </div>
            </div>
        </nav>
    }
}

const mapStateToProps = (state) => {
    return state.auth;
};

const
    mapDispatchToProps = (dispatch, state) => {
        return {
            setSelectedShow: (id) => dispatch(actions.setSelectedShow(id)),
            getShow: (id) => dispatch(actions.getShow(id)),
            preloadClient: (history) => dispatch(actions.preloadClient(history)),
        };
    };


export default connect(mapStateToProps, mapDispatchToProps)(Navbar);
