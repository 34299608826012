import React from "react";
import { Form } from "react-bootstrap";

const CheckMe = () => {
  //const scrollContainerStyle = { width: "800px", maxHeight: "400px" };
  return (
    <div className="row" dir="rtl">
      <div className="col-12 text-right">
        <Form.Group controlId="formBasicCheckbox">
          <Form.Check
            type="checkbox"
            label="  --   אנחנו מעוניינים לקבל עדכונים ותוכן שיווקי הקשור לפעילות"
          ></Form.Check>
        </Form.Group>
      </div>
      <div className="col-12 text-right">
        <Form.Group controlId="formBasicCheckbox">
          <Form.Check
            type="checkbox"
            label="    --  תבדוק אותי אנחנו מאשרים ומסכימים עם תנאי השימוש והתקנון כפי שמופיעים באתר"
          ></Form.Check>
        </Form.Group>
      </div>
    </div>
  );
};

export default CheckMe;
