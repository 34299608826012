import axios from "axios";
import * as actionTypes from "./actionTypes";

export const authSuccessClient = (client) => {
    client.type = actionTypes.AUTH_SUCCESS_CLIENT
    return client;
};

export const setCookie = (name, value, age = 3600) => {
    let d = new Date();
    d.setTime(d.getTime() + (age * 1000));
    let expires = "expires=" + d.toUTCString();
    if (typeof value === "object") value = JSON.stringify(value);
    document.cookie = `${name}=${value}; expires=${expires}; path=/;`;
}

export const getCookie = (cname) => {
    let name = cname + "=";
    let ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}

export const removeCookie = (name) => {
    document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
}

export const authFail = (error) => {
    return {
        type: actionTypes.AUTH_FAIL,
        error: error,
    };
};

export const logout = () => {
    return {
        type: actionTypes.AUTH_LOGOUT,
    };
};

export const setSettings = (data) => {
    return {
        type: actionTypes.SET_SETTINGS,
        settings: data
    };
}

export const setClient = (data) => {
    return {
        type: actionTypes.SET_CLIENT,
        client: data
    };
}

export const setShow = (data) => {
    return {
        type: actionTypes.SET_SHOW,
        show: data
    };
}

export const getSettings = () => dispatch => {

    //read from cookie
    let settings = getCookie('settings');
    if (settings) {
        try {
            let parsedSettings = JSON.parse(settings);
            dispatch(setSettings(parsedSettings));
            return;
        } catch (e) {
            console.log("Got a bad cookie.");
            removeCookie('settings');
        }
    }

    let config = {
        method: 'get',
        url: 'https://livem.tv/api/',
        headers: {
            'Action': 'getSettings'
        },
        data: ''
    };
    axios(config)
        .then(function (response) {
            if (response.data.status) {
                setCookie('settings', response.data.settings);
                dispatch(setSettings(response.data.settings));
            }
        })
        .catch(function (error) {
            console.log(error);
        });
    // }
};

export const preloadClient = (history) => dispatch => {

    let client = getCookie('client');
    if (client) {
        let parsedClient = JSON.parse(client);
        if (parsedClient.code) {
            dispatch(setClient(parsedClient));
        } else {
            history.push('/');
        }
    } else {
        history.push('/');
    }
}

export const authClient = async (code) => dispatch => {

    let config = {
        method: 'get',
        url: `https://livem.tv/api/?code=${code}`,
        headers: {
            'Action': 'getClient'
        }
    };
    return axios(config)
};

export const getShow = (id) => dispatch => {
    let config = {
        method: 'get',
        url: `https://livem.tv/api/?id=${id}`,
        headers: {
            'Action': 'getShow'
        }
    };
    axios(config)
        .then((response) => {
            if (response.data.status) {
                dispatch(setShow(response.data.show));
            }
        })
        .catch(function (error) {
            console.log(error);
        });
};

export const setSelectedShow = (id) => dispatch => {
    dispatch({
        type: actionTypes.SET_SHOW_SELECTED,
        selectedShow: id
    });
};

export const changeToolbarTitle = () => {
    return {
        type: actionTypes.CHANGE_TITLE,
    };
};

export const userAuth = (pinCode) => {
    let data = JSON.stringify({"id": 1, "pin": pinCode});
    const config = {
        method: 'post',
        url: 'https://livem.tv/api/',
        headers: {
            'Action': 'authUser',
            'Content-Type': 'application/json'
        },
        data: data
    };

    axios(config)
        .then(function (response) {
            console.log(JSON.stringify(response.data));
        })
        .catch(function (error) {
            console.log(error);
        });
}

export const setUser = (user) => {
    return {
        type: actionTypes.SET_USER,
        user: user
    };
}

export const setShowView = (flag) => {
    console.log("set show view")
    return {
        type: actionTypes.SET_SHOW_VIEW,
        showView: flag
    };
}


export const userGet = (tel, code, token) => {
    //var axios = require('axios');
    //var data = JSON.stringify({"tel":"0504717977","code":"1234","token":"wjviewrnvbiebvirebv"});
    //code => should be Company code; token => pin-code??
    let data = JSON.stringify({"tel": tel, "code": code, "token": token});

    const config = {
        method: 'post',
        url: 'https://livem.tv/api/',
        headers: {
            'Action': 'getUser',
            'Content-Type': 'application/json'
        },
        data: data
    };

    axios(config)
        .then(function (response) {
            console.log(JSON.stringify(response.data));
        })
        .catch(function (error) {
            console.log(error);
        });
}