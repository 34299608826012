export const SET_SETTINGS = "SET_SETTINGS";
export const SET_CLIENT = "SET_CLIENT";
export const SET_SHOW = "SET_SHOW";
export const SET_SHOW_SELECTED = "SET_SHOW_SELECTED";
export const AUTH_START = "AUTH_START";
export const AUTH_SUCCESS_CLIENT = "AUTH_SUCCESS_CLIENT";
export const AUTH_FAIL = "AUTH_FAIL";
export const AUTH_LOGOUT = "AUTH_LOGOUT";
export const CHANGE_TITLE = "CHANGE_TITLE";
export const SET_USER = "SET_USER";
export const SET_SHOW_VIEW = "SET_SHOW_VIEW";

