import React, {Component} from "react";
import {
    Switch,
    Route,
    withRouter,
    Redirect,
} from "react-router-dom";
import {connect} from "react-redux";
import SignIn from "./containers/SingIn/SingIn";
import AuthPage from "./containers/AuthPhone/Auth";
import LiveStream from "./containers/LiveStream/LiveStream";
import "./App.scss";
import SideBar from "./components/Navigation/SideBar/SideBar";
import Navbar from "./components/Navigation/Navbar/Navbar";
import Footer from "./components/Navigation/Footer/Footer";

class App extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showSideBar: false
        }
    }

    sideBarClosedHandler = () => {
        this.setState({showSideBar: false});
    };

    sideBarToggleHandler = () => {
        this.setState((prevState) => {
            return {showSideBar: !prevState.showSideBar};
        });
    };

    componentDidMount() {
        if (window.innerHeight < window.innerWidth)
            setTimeout(() => {
                this.setState({showSideBar: true})
            }, 500)
    }

    render() {
        return (
            <div className="App">
                <div>
                    <SideBar
                        facebook={this.props.client.facebook}
                        youtube={this.props.client.youtube}
                        instagram={this.props.client.instagram}
                        open={this.state.showSideBar}
                        code={this.props.client.code}
                        show={this.props.show.id}
                        closed={this.sideBarClosedHandler}
                    />
                    <Navbar
                        isAuth={this.props.auth.isClientAuthorized}
                        drawerToggleClicked={this.sideBarToggleHandler}
                        customerName={this.props.client.title}
                        customerLogo={this.props.client.logo_is_text ? null : this.props.client.logo}
                        customerShows={this.props.client.shows}
                    />
                    <main id='main' className={'content ' + (this.state.showSideBar ? 'narrow' : '')}>
                        <div
                            className={'content-background desktop ' + (this.props.show.id && this.props.showView ? 'blur' : '')}
                            style={{backgroundImage: ('url("' + this.props.client.background + '")')}}/>
                        <div
                            className={'content-background mobile ' + (this.props.show.id && this.props.showView ? 'blur' : '')}
                            style={{backgroundImage: ('url("' + this.props.client.background_mobile + '")')}}/>
                        <Switch>
                            <PrivateRoute auth={this.props.auth.isClientAuthorized} path="/auth" exact
                                          component={AuthPage}/>
                            <PrivateRoute auth={this.props.auth.isSessionAuthorized} path="/live" exact
                                          component={LiveStream}/>
                            <PrivateRoute auth={this.props.auth.isSessionAuthorized} path="/live" exact
                                          component={LiveStream}/>
                            <PrivateRoute auth={this.props.auth.isSessionAuthorized} path="/live/:show" exact
                                          component={LiveStream}/>
                            <Route path="/c/:client" exact component={SignIn}/>
                            <Route path="/c/:client/g/:code" exact component={AuthPage}/>
                            <Route path="/" exact component={SignIn}/>
                            <Redirect to="/"/>
                        </Switch>
                    </main>
                    <Footer client={this.props.client}/>
                </div>
            </div>
        );
    }
}

const PrivateRoute = ({auth, children, ...rest}) => {
    return (
        <Route
            {...rest}
            render={({location}) =>
                auth ? (
                    children
                ) : (
                    <Redirect
                        to={{
                            pathname: "/",
                            state: {from: location}
                        }}
                    />
                )
            }
        />
    );
}

const mapStateToProps = (state) => {
    return state.auth;
};

export default withRouter(connect(mapStateToProps)(App));