import React from "react";
import { Form } from "react-bootstrap";

const InputDate = () => {
  //const scrollContainerStyle = { width: "800px", maxHeight: "400px" };
  return (
    <div>
      <div className="col">
        <div className="col-md-6 offset-md-3">
          <Form.Group controlId="dob ">
            <Form.Label>תאריך לידה</Form.Label>
            <Form.Control
              type="date"
              name="dob"
              placeholder="Select Date"
            />
          </Form.Group>
        </div>
      </div>
    </div>
  );
};
// className="bg-dark text-light"
export default InputDate;
